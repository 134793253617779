<template>
    <div v-if="isMounted" id="loan-request-step-detail" class="px-8">
        <div v-if="!isRejected" class="vx-row text-start">
            <!-- SOLICITUD -->
            <div v-if="steps[0] == 1" class="vx-col w-full mb-base">
                <h2 class="mb-16">Tu cliente está complementando su solicitud</h2>
                <div class="vx-row">
                    <div v-if="isSFV" class="vx-col sm:w-full md:w-1/2 lg:w-5/12 mb-base">
                        <h3 class="mb-4">¿Qué debo hacer?</h3>
                        <div class="vx-row">
                            <div class="vx-col w-1/2 mb-4">
                                <h5 class="mb-4">Información del proyecto</h5>
                                <div class="flex mb-2">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasTechnicalData ? 'text-primary' : 'text-grey'" :icon="hasTechnicalData ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="mb-2 black">
                                        Completa la información del proyecto
                                    </small>
                                </div>
                                <vs-button @click.stop="goToPage(0)" size="small" color="black">Completar</vs-button>
                            </div>
                            <div class="vx-col w-1/2">
                                <img :src="getIcon('01')" alt="Completando solicitud">
                            </div>
                        </div>
                    </div>
                    <div class="vx-col sm:w-full md:w-1/2 lg:w-5/12">
                        <h3 class="mb-4">¿Qué falta?</h3>
                        <div class="vx-row">
                            <div class="vx-col w-1/2">
                                <h5 class="mb-4">Información del proyecto</h5>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasPersonalGeneralDataCompleted ? 'text-primary' : 'text-grey'" :icon="hasPersonalGeneralDataCompleted ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Datos generales
                                    </small>
                                </div>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasPersonalOccupationDataCompleted ? 'text-primary' : 'text-grey'" :icon="hasPersonalOccupationDataCompleted ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Ocupación/Ingresos
                                    </small>
                                </div>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasCDCDataCompleted ? 'text-primary' : 'text-grey'" :icon="hasCDCDataCompleted ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Consulta crediticia
                                    </small>
                                </div>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasGuaranteeDataCorrect() ? 'text-primary' : 'text-grey'" :icon="hasGuaranteeDataCorrect() ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Referencias y obligado solidario
                                    </small>
                                </div>
                            </div>
                            <div class="vx-col w-1/2">
                                <img :src="getIcon('02')" alt="Completando solicitud">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- DOCUMENTOS -->
            <div v-if="steps[1] == 1" class="vx-col w-full mb-base">
                <h2 class="mb-16">Tu cliente está compartiendo su documentación</h2>
                <h3 class="mb-base">¿Qué falta?</h3>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-1/2 lg:w-5/12">
                        <div class="vx-row">
                            <div class="vx-col w-1/2 mb-base">
                                <h5 class="mb-4">Documento del solicitante</h5>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="documentStatus ? 'text-primary' : 'text-grey'" :icon="documentStatus ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Documentos generales
                                    </small>
                                </div>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasPersonalIdentityVerification ? 'text-primary' : 'text-grey'" :icon="hasPersonalIdentityVerification ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Verificación de identidad
                                    </small>
                                </div>
                            </div>
                            <div class="vx-col w-1/2">
                                <img :src="getIcon('02')" alt="Documentos de la solicitud">
                            </div>
                        </div>
                    </div>
                    <div class="vx-col sm:w-full md:w-1/2 lg:w-5/12">
                        <h3></h3>
                        <div class="vx-row">
                            <div class="vx-col w-1/2">
                                <h5 class="mb-4">Obligado solidario</h5>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasGuaranteeDataCorrect(1) ? 'text-primary' : 'text-grey'" :icon="hasGuaranteeDataCorrect(1) ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Comprobante de domicilio
                                    </small>
                                </div>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasGuaranteeDataCorrect(2) ? 'text-primary' : 'text-grey'" :icon="hasGuaranteeDataCorrect(2) ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Consulta crediticia
                                    </small>
                                </div>
                                <div class="flex">
                                    <feather-icon class="mr-2 h-5 w-5" :class="hasGuaranteeDataCorrect(3) ? 'text-primary' : 'text-grey'" :icon="hasGuaranteeDataCorrect(3) ? 'CheckSquareIcon' : 'SquareIcon'" />
                                    <small class="black">
                                        Verificación de identidad
                                    </small>
                                </div>
                                <p @click.stop="requestNotificationToGuarantee()" class="black mt-1 mb-4">
                                    <u>Envíale un recordatorio</u>
                                </p>
                            </div>
                            <div class="vx-col w-1/2">
                                <img :src="getIcon('03')" alt="Completando solicitud">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- REVISIÓN DE SOLICITUDES -->
            <div v-if="steps[2] == 1" class="vx-col w-full mb-base">
                <h2 class="md:mb-16 sm:mb-16">Estamos revisando la solicitud</h2>
                <div class="vx-row">
                    <div class="vx-col sm:w-3/4 md:w-2/3 lg:w-2/3">
                        <h3 class="mb-base">¿Qué falta?</h3>
                        <p class="black">
                            Tu cliente completó su solicitud y estamos verificándola para poder aprobar el crédito.
                            <br>
                            <br>
                            Recibirás un correo en 1 día hábil para confirmar el estado de la solicitud.
                        </p>
                    </div>
                    <div class="vx-col sm:w-1/4 md:w-1/3 lg:w-1/3">
                        <img :src="getIcon('04')" alt="Documentos de la solicitud">
                    </div>
                </div>
            </div>
            <!-- CRÉDITO APROBADO -->
            <div v-if="steps[3] == 1" class="vx-col w-full mb-base">
                <h2 class="mb-base">¡Crédito Aprobado!</h2>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-base">
                        <p class="black">
                            Enviamos una oferta de crédito a tu cliente. El fondeo del proyecto comenzará cuando tu
                            cliente acepte la oferta.
                        </p>
                    </div>
                    <div class="vx-col sm:w-3/4 md:w-2/3 lg:w-2/3">
                        <h3 class="mb-base">¿Qué falta?</h3>
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-1/4 mb-base">
                                <h5 class="mb-2">Oferta del crédito</h5>
                                <p>Pendiente de aceptar</p>
                            </div>
                            <div class="vx-col sm:w-full md:w-1/6 mb-4">
                                <img :src="getIcon('05')" alt="Oferta de la solicitud.">
                            </div>
                            <div class="vx-col w-full">
                                <a class="black" :href="
                                `mailto:${user.email}?subject=Acepta tu crédito aprobado&body=Hola, Te escribo para recordarte que RedGirasol aprobó tu crédito y solo debes aceptar la oferta. Por favor, ingresa aquí para aceptar tu crédito.`">
                                    <u>Envíale un recordatorio a tu cliente.</u>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CRÉDITO EN FONDEO -->
            <div v-if="steps[4] == 1" class="vx-col w-full mb-base">
                <h2 class="mb-base">¡Crédito en fondeo!</h2>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-base">
                        <p class="black">
                            Tu cliente aceptó la oferta y el proyecto ya se encuentra en fondeo.
                            <br>
                            <br>
                            Estamos generando los contratos y recibirás un correo cuando estén listos para firmar.
                        </p>
                    </div>
                    <div class="vx-col sm:w-3/4 md:w-2/3 lg:w-2/3">
                        <h3 class="mb-base">¿Qué falta?</h3>
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-2/3">
                                <project-fund-progress :total="project.finance.financed_amount"
                                    :invested="project.invested" :percentage="project.percentage"
                                    :remaining_days="project.dias_restantes" :investors="project.inversionistas" />
                            </div>
                            <div class="vx-col sm:w-full md:w-1/3">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Contratos:</h5>
                                        <p>Pendiente de generar</p>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2">
                                        <img :src="getIcon('02')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FIRMA DE CONTRATOS -->
            <div v-if="steps[5] == 1" class="vx-col w-full mb-base">
                <h2 class="mb-base">Firma de contratos</h2>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-12">
                        <p class="black">
                            Ya puedes firmar los contratos mientras avanza el fondeo del proyecto.
                        </p>
                        <vs-button @click.stop="goToPage(3)" class="mt-4" size="small" color="black">Firmar contratos</vs-button>
                    </div>
                    <div class="vx-col w-full">
                        <h3 class="mb-base">¿Qué falta?</h3>
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-1/3 mb-base">
                                <project-fund-progress :total="project.finance.financed_amount"
                                    :invested="project.invested" :percentage="project.percentage"
                                    :remaining_days="project.dias_restantes" :investors="project.inversionistas" />
                            </div>
                            <div class="vx-col sm:w-full md:w-2/3">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/3 mb-base">
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                                <h5 class="mb-2">Contratos:</h5>
                                                <p :class="getContractsGroupStatus().color">
                                                    {{ getContractsGroupStatus().text }}
                                                </p>
                                                <small @click.stop="goToPage(3)" class="clickable-img black">
                                                    <u>Ver detalle</u>
                                                </small>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2">
                                                <img :src="getIcon('02')" alt="Oferta de la solicitud.">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/3 mb-base">
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                                <h5 class="mb-2">Anexos:</h5>
                                                <p :class="getContractsGroupStatus(true).color">
                                                    {{ getContractsGroupStatus(true).text }}
                                                </p>
                                                <small @click.stop="goToPage(3)" class="clickable-img black">
                                                    <u>Ver detalle</u>
                                                </small>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2">
                                                <img :src="getIcon('02')" alt="Oferta de la solicitud.">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="isSFV" class="vx-col sm:w-full md:w-1/3">
                                        <div class="vx-row">
                                            <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                                <h5 class="mb-2">Factura:</h5>
                                                <p :class="hasInvoices ? 'text-primary' : 'text-grey'">{{ hasInvoices ? "Cargadas" : "Pendientes de cargar" }}</p>
                                                <small @click.stop="goToPage(2)" class="clickable-img black flex">
                                                    <feather-icon icon="UploadIcon" class="w-4 h-4 mt-0 mr-1"></feather-icon>
                                                    <u>Subir</u>
                                                </small>
                                            </div>
                                            <div class="vx-col sm:w-full md:w-1/2">
                                                <img :src="getIcon('02')" alt="Oferta de la solicitud.">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PROYECTO EN INSTALACIÓN -->
            <div v-if="steps[6] == 1 && isSFV" class="vx-col w-full mb-base">
                <h2 class="mb-base">¡Proyecto en instalación!</h2>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-12">
                        <p class="black">
                            Recibiste el anticipo el <strong>{{ midDateFormat(project.installation_date) }}</strong>. Recuerda que
                            tienes 30 días para concluir la instalación e iniciar trámite de interconexión.
                            <br><br>
                            Recibirás el resto del crédito cuando firmes los anexos y compartas las evidencias.
                        </p>
                    </div>
                    <div class="vx-col w-full">
                        <h3 class="mb-base">¿Qué falta?</h3>
                        <div class="vx-row">
                            <div class="vx-col sm:w-1/2 md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Datos técnicos:</h5>
                                        <p :class="fundData.serial_numbers == 'Incompletas' ? 'text-grey' : 'text-primary'">{{ fundData.serial_numbers == "Incompletas" ? "Pendiente" : "Completado" }}</p>
                                        <small @click.stop="goToPage(2)" class="clickable-img black flex">
                                            <feather-icon icon="UploadIcon" class="w-4 h-4 mt-0 mr-1"></feather-icon>
                                            <u>Subir</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2">
                                        <img :src="getIcon('06')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Evidencias:</h5>
                                        <p :class="project.evidence_verification_request_date == null ? 'text-grey' : 'text-primary'">{{ project.evidence_verification_request_date == null ? "Pendiente" : "Completado" }}</p>
                                        <small @click.stop="goToPage(2)" class="clickable-img black flex">
                                            <feather-icon icon="UploadIcon" class="w-4 h-4 mt-0 mr-1"></feather-icon>
                                            <u>Subir</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2">
                                        <img :src="getIcon('07')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Anexos:</h5>
                                        <p :class="getContractsGroupStatus(true).color">
                                            {{ getContractsGroupStatus(true).text }}
                                        </p>
                                        <small @click.stop="goToPage(3)" class="clickable-img black">
                                            <u>Ver detalle</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2">
                                        <img :src="getIcon('02')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Encuesta:</h5>
                                        <p :class="getSurveyStatus().color">{{ getSurveyStatus().text }}</p>
                                        <small v-if="getSurveyStatus().text == 'Pendiente'" @click.stop="goToPage(2)" class="clickable-img black flex">
                                            <feather-icon icon="UploadIcon" class="w-4 h-4 mt-0 mr-1"></feather-icon>
                                            <u>Enviar al completar evidencias</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2">
                                        <img :src="getIcon('08')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PROYECTO ENTREGADO -->
            <div v-if="steps[7] == 1" class="vx-col w-full mb-base">
                <h2 class="mb-base">¡Proyecto en instalación!</h2>
                <div class="vx-row">
                    <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3 mb-12">
                        <p class="black">
                            Recibiste el anticipo el <strong>{{ midDateFormat(project.installation_date) }}</strong>. Recuerda que
                            tienes 30 días para concluir la instalación e iniciar trámite de interconexión.
                            <br><br>
                            Recibirás el resto del crédito cuando firmes los anexos y compartas las evidencias.
                        </p>
                    </div>
                    <div class="vx-col w-full">
                        <h3 class="mb-base">¿Qué falta?</h3>
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Datos técnicos:</h5>
                                        <p :class="fundData.serial_numbers == 'Incompletas' ? 'text-grey' : 'text-primary'">{{ fundData.serial_numbers == "Incompletas" ? "Pendiente" : "Completado" }}</p>
                                        <small @click.stop="goToPage(2)" class="clickable-img black flex">
                                            <feather-icon icon="UploadIcon" class="w-4 h-4 mt-0 mr-1"></feather-icon>
                                            <u>Subir</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2  mb-2">
                                        <img :src="getIcon('06')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Evidencias:</h5>
                                        <p :class="project.evidence_verification_request_date == null ? 'text-grey' : 'text-primary'">{{ project.evidence_verification_request_date == null ? "Pendiente" : "Completado" }}</p>
                                        <small @click.stop="goToPage(2)" class="clickable-img black flex">
                                            <feather-icon icon="UploadIcon" class="w-4 h-4 mt-0 mr-1"></feather-icon>
                                            <u>Subir</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2  mb-2">
                                        <img :src="getIcon('07')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Anexos:</h5>
                                        <p :class="getContractsGroupStatus(true).color">
                                            {{ getContractsGroupStatus(true).text }}
                                        </p>
                                        <small @click.stop="goToPage(3)" class="clickable-img black">
                                            <u>Ver detalle</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2  mb-2">
                                        <img :src="getIcon('02')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col sm:w-1/2 md:w-1/4">
                                <div class="vx-row">
                                    <div class="vx-col sm:w-full md:w-1/2 mb-base">
                                        <h5 class="mb-2">Encuesta:</h5>
                                        <p :class="getSurveyStatus().color">{{ getSurveyStatus().text }}</p>
                                        <small v-if="getSurveyStatus().text == 'Pendiente'" @click.stop="goToPage(2)" class="clickable-img black flex">
                                            <feather-icon icon="UploadIcon" class="w-4 h-4 mt-0 mr-1"></feather-icon>
                                            <u>Enviar al completar evidencias</u>
                                        </small>
                                    </div>
                                    <div class="vx-col sm:w-full md:w-1/2  mb-2">
                                        <img :src="getIcon('08')" alt="Oferta de la solicitud.">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FIN DE FLUJO -->
            <div v-if="isCompleted" class="vx-col w-full mb-base">
                <h2 class="mb-base">¡Proyecto entregado!</h2>
                <div class="vx-row">
                    <div class="vx-col w-10/12">
                        <p class="black mb-base">
                            Gracias por ayudarnos a transformar al mundo un crédito a la vez.
                            <br><br>
                            <strong>Tu opinión es lo más importante</strong>
                        </p>
                        <vs-button @click="showFeedback()" color="black" size="small">Califica tu experiencia</vs-button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="vx-row text-start px-16 mb-base">
            <div class="vx-col w-full mb-base">
                <div class="vx-row">
                    <div class="vx-col w-2/3">
                        <h2 class="md:mb-16 sm:mb-16">Solicitud rechazada</h2>
                        <p class="black">
                            Lamentablemente no podemos aprobar el crédito de tu cliente. 
                            <br><br>
                            Sugerimos que tu cliente intente nuevamente en un par de meses.
                        </p>
                    </div>
                    <div class="vx-col w-1/3">
                        <img :src="getIcon('09')" alt="Documentos de la solicitud">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProjectFundProgress from '@components/supplier/projects/ProjectFundProgress.vue'
import dateHelper from "@mixins/dateHelper";

export default {
    name: "LoanRequestStepDetail",
    mixins: [dateHelper],
    props: ["project", "technicalValidations"],
    data: () => ({
        isMounted: false,
        steps: [0, 0, 0, 0, 0, 0, 0, 0],
        pills: [
            { status: 0, name: "Solicitud" },
            { status: 1, name: "Documentación" },
            { status: 2, name: "En revisión" },
            { status: 3, name: "Aprobado" },
            { status: 4, name: "En fondeo" },
            { status: 5, name: "Firma de contratos" },
            { status: 7, name: "Entregado" }
        ],
        isCompleted: false,
        documentStatus: null,
        fundData: null
    }),
    components: {
        ProjectFundProgress
    },
    mounted() {
        this.isMounted = false;
        this.showStepDetail();
        this.getDocumentStatus();
        if(this.isSFV) {
            this.loadPostFundData();
        }
        this.isMounted = true;
    },
    computed: {
        isMoral() {
            return this.client.user.person_type == 0;
        },
        isSFV() {
            return this.project.finance.loan_type == 1;
        },
        finance() {
            return this.project.finance;
        },
        client() {
            return this.project.client;
        },
        user() {
            return this.client.user;
        },
        personal() {
            return this.user.personal;
        },
        business() {
            return this.user.business;
        },
        hasPersonalGeneralDataCompleted()
        {
            if(this.isMoral) {

            } else {
                return this.personal.address != null ? this.personal.address.neighborhood_id != null : false;
            }
        },
        hasPersonalOccupationDataCompleted()
        {
            if(this.isMoral) {

            } else {
                return this.personal.personal_occupation_id != null
            }
        },
        hasCDCDataCompleted()
        {
            if(this.isMoral) {

            } else {
                return this.personal.last_credit_request != null
            }
        },
        guaranteesList()
        {
            return this.project.finance.guarantees_list;
        },
        hasPersonalIdentityVerification()
        {
            if(this.isMoral) {
                return this.business.personal.identity_verification_dashboard_url != null;
            } else {
                return this.personal.identity_verification_dashboard_url != null;
            }
        },
        contracts()
        {
            return this.project.contracts.filter(x => x.contract_group_id == 1 ||  x.contract_group_id == 2 );
        },
        contractAnnex()
        {
            return this.project.contracts.filter(x => x.contract_group_id == 4 ||  x.contract_group_id == 3 );
        },
        hasTechnicalData()
        {
            if(this.technicalValidations != null && this.technicalValidations.validations.length > 0) {
                return this.technicalValidations.validations[0].dataValidations.complete;
            } else {
                return false;
            }
        },
        hasInvoices()
        {
            return this.project.pdf_invoices.length > 0 && this.project.xml_invoices.length > 0;
        },
        isRejected()
        {
            return this.project.rejected_date != null;
        }
    },
    methods: {
        getIcon(icon) {
            const icons = {
                "01": "01_engine_icon.png"
                , "02": "02_document_icon.png"
                , "03": "03_user_icon.png"
                , "04": "04_search_clock_icon.png"
                , "05": "05_money_icon.png"
                , "06": "06_cpu_icon.png"
                , "07": "07_image_icon.png"
                , "08": "08_survey_icon.png"
                , "09": "09_loan_rejected.png"
            };

            return `https://cdn.redgirasol.com/suppliers/resources/icons/${icons[icon] || ""}`;
        },
        newGetDate(s) {
            let dd = null;
            switch (s) {
                case 0:
                    dd = this.project.loan_review_request_timestamp;
                    break;
                case 1:
                    dd = this.project.documentation_complete_date;
                    break;
                case 2:
                    dd = this.project.loan_proposal_timestamp;
                    break;
                case 3:
                    dd = this.project.public_date;
                    break;
                case 4:
                    dd = this.project.contracts_created_at;
                    break;
                case 5:
                    dd = this.project.installation_date;
                    break;
                case 6:
                    dd = this.project.installation_date;
                    break;
                case 7:
                    dd = this.project.delivery_date;
                    break;
            }
            if (dd != null) {
                return dd;
            }
            else {
                return null;
            }
        },
        showStepDetail() {

            if(this.isSFV) {
                this.pills = [
                    { status: 0, name: "Solicitud" },
                    { status: 1, name: "Documentación" },
                    { status: 2, name: "En revisión" },
                    { status: 3, name: "Aprobado" },
                    { status: 4, name: "En fondeo" },
                    { status: 5, name: "Firma de contratos" },
                    { status: 6, name: "En instalación" },
                    { status: 7, name: "Entregado" }
                ]
            }

            let isCompleted = 0;

            for (let index = 0; index < this.pills.length; index++) {
                const element = this.pills[index];
                const newDate = this.newGetDate(element.status);

                // console.log(`${element.status} - ${newDate}`);
                if(newDate == null) {
                    this.steps[element.status] = 1;
                    break;
                } else {
                    this.steps[element.status] = 0;
                    isCompleted ++
                }
            }

            // console.log(this.pills.length, isCompleted);
            this.isCompleted = isCompleted == this.pills.length;
        },
        hasGuaranteeDataCorrect(step = 0) {
            const flags = [];

            switch (step) {
                case 0:
                    this.guaranteesList.forEach(os => {
                        if(os.is_guarantee == 1) {
                            if(os.guarantee_profile != null) {
                                const profile = os.guarantee_profile;
                                if(
                                    profile.rejected_date == null &&
                                    (profile.email != null && profile.email != "")
                                ) {
                                    flags.push(true);
                                }
                            }
                        }
                    });
                    break;

                    case 1: // DIRECCIÓN
                        this.guaranteesList.forEach(os => {
                            if(os.is_guarantee == 1) {
                                if(os.guarantee_profile != null) {
                                    const profile = os.guarantee_profile;
                                    const guaranteeIsMoral = profile.business_profile_id != null;
                                    const address = guaranteeIsMoral ? profile.business.address : profile.personal.address;
                                    if(
                                        address != null 
                                        && address.neighborhood_id != null
                                    ) {
                                        flags.push(true);
                                    }
                                }
                            }
                        });
                    break;
                    case 2: // CDC
                        this.guaranteesList.forEach(os => {
                            if(os.is_guarantee == 1) {
                                if(os.guarantee_profile != null) {
                                    const profile = os.guarantee_profile;
                                    const guaranteeIsMoral = profile.business_profile_id != null;
                                    const fiscal = guaranteeIsMoral ? profile.business : profile.personal;
                                    if(
                                        fiscal != null
                                        && fiscal.last_credit_request != null
                                    ) {
                                        flags.push(true);
                                    }
                                }
                            }
                        });
                    break;
                    case 3: // Verificación de identidad
                        this.guaranteesList.forEach(os => {
                            if(os.is_guarantee == 1) {
                                if(os.guarantee_profile != null) {
                                    const profile = os.guarantee_profile;
                                    const guaranteeIsMoral = profile.business_profile_id != null;
                                    const personal = guaranteeIsMoral ? profile.business.personal : profile.personal;
                                    if(
                                        personal != null
                                        && personal.identity_verification_dashboard_url != null
                                    ) {
                                        flags.push(true);
                                    }
                                }
                            }
                        });
                    break;
                default:
                    break;
            }

            return flags.length > 0;
        },
        requestNotificationToGuarantee() {
            this.$vs.dialog({
                type: 'confirm',
                color: '#3B3A3E',
                title: 'Mensaje enviado',
                text: `Se enviará un recordatorio al obligado solidario de tu cliente para que concluya su proceso.`,
                acceptText: "Enviar",
                cancelText: 'Cancelar',
                accept: this.doSendGuaranteeReminder
            });
        },
        doSendGuaranteeReminder()
        {
            try {
                this.showLoading(true);
                this.guaranteesList.forEach(element => {
                    axios.post(`/api/guarantee/post/${element.id}/send-reminder/1`);
                });
                
                this.saveSuccessNotif();
                
                this.showLoading(false);
            } catch (error) {
                this.showLoading(false);
                this.failedOperationNotif();
            } finally {
                this.showLoading(false);
            }
        },
        getContractsGroupStatus(isExtraDocuments = false)
        {
            try {
                const contracts = !isExtraDocuments ? this.contracts : this.contractAnnex;

                let status = {
                    color: "text-grey",
                    text: "Pendiente generar"
                };
            
                if(contracts != null) {
                    for (let index = 0; index < contracts.length; index++) {
                        const element = contracts[index];
                        element
                        if(element.status == "generated") {
                            status.text = "Pendiente de envío";
                            break;
                        } else if (element.status == "sent") {
                            status.text = "Contratos firmados";
                            break;
                        } else if (element.status == "signed" || element.status == "verified") {
                            status.text = "Contratos firmados";
                            status.color = "text-primary";
                        }
                    }
                }


                return status;

            } catch (error) {
                console.log("No se pudo calcular el estatus de los contratos.", error)
            }
        },
        /**
         *  requestTabIndex: 0,
         *  prefundTabIndex: 1,
         *  postfundTabIndex: 2,
         *  contractsTabIndex: 3,
         */
        goToPage(index)
        {
            this.$emit("goToPage", index);
        },
        showFeedback(){
            this.$store.commit('TOGGLE_IS_FEEDBACK_POPUP_ACTIVE', true)
        },
        async getDocumentStatus()
        {
            try {
                const res = await axios.get(`/api/document/get/status/${this.project.id}`);
                this.documentStatus = res.data.document_status;
            } catch (error) {
                return false;
            }
        },
        async loadPostFundData(){
            try {
                let res = await axios.get(`/api/loan/get/status/post-fund/${this.project.id}`);
                // let res = await axios.get(`/api/loan-onboarding/get/not-assigned-loans`);
                this.fundData = res.data;
            }
            catch (e) {
                this.warn(e);
            }
        },
        getSurveyStatus() 
        {
            let status = {
                color: "text-grey",
                text: "Pendiente"
            };

            if(this.project.applicant_feedback_request_date != null) {
                status.text = "Encuesta enviada";
            }

            if(this.project.applicant_feedback_date != null) {
                status.text = "Encuesta completada";
                status.color = "text-primary";
            }

            return status;
        }
    }
}
</script>

<style>
.text-start {
    text-align: start;
}

.align-lists {
    left: -2rem;
    position: relative;
    margin-bottom: 0;
}

.img-center {
    text-align: -webkit-center;
}
</style>